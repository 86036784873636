import React            from 'react';
import PropTypes        from 'prop-types';
import Modal            from '../Modal';
import Translations     from '../../utils/Translations';
import TextInput        from '../Text/TextInput';
import Flux             from '../../flux/Flux';

const draftJsMaxLength = 1200;

let redirectTimeout  = null;
let timeLimitTimeout = null;

class SurveyFeedback extends React.Component {

	static noop(e) {
		if (typeof e === 'object') {
			e.preventDefault();
			e.stopPropagation();
		}
		// do nothing
	}

	static onVoteClick(e) {
		SurveyFeedback.noop(e);
	}

	static postCloseCleanup() {
		if (redirectTimeout) {
			clearTimeout(redirectTimeout);
		}

		if (timeLimitTimeout) {
			clearTimeout(timeLimitTimeout);
		}

		Flux.Survey.closeSurvey(Flux.Constants.SurveyTypes.FEEDBACK);
	}

	static validateEmail(email) {
		const re = Flux.Constants.RegExp.EMAIL;
		return re.test(email);
	}

	constructor(props) {
		super(props);

		const surveyData         = Flux.Survey.getSurveyData(Flux.Constants.SurveyTypes.FEEDBACK);
		const teasers            = surveyData && typeof surveyData.teasers !== 'undefined' ? surveyData.teasers : [];

		this.state = {
			modalClosing:         false,
			sending:              false,
			surveyStored:         false,
			timeLimit:            false,
			hasMaxChars:          false,
			emailValidationError: false,
			teasers:              teasers,
		};

		this.surveyInputFeedback = null;
		this.surveyInputEmail    = null;
		this.surveyModal         = null;

		this.onFeedbackMessageChange = this.onFeedbackMessageChange.bind(this);
		this.onSurveyDataChange      = this.onSurveyDataChange.bind(this);
		this.storeSurvey             = this.storeSurvey.bind(this);
		this.timeLimitCallback       = this.timeLimitCallback.bind(this);
		this.timeoutCallback         = this.timeoutCallback.bind(this);
		this.unsetEmailError         = this.unsetEmailError.bind(this);
	}

	componentDidMount() {
		Flux.Survey.addSurveyChangeListener(this.onSurveyDataChange);

		if (Flux.Survey.getSurveyData(Flux.Constants.SurveyTypes.FEEDBACK) === null) {
			Flux.Survey.loadFeedbackData();
		}
	}

	componentWillUnmount() {
		Flux.Survey.removeSurveyChangeListener(this.onSurveyDataChange);
	}

	onFeedbackMessageChange() {

		const newHasMaxChars = draftJsMaxLength - this.surveyInputFeedback.getPlainText(true).length <= 0;

		if (newHasMaxChars !== this.state.hasMaxChars) {
			this.setState({
				hasMaxChars: newHasMaxChars,
			});
		}

	}

	onSurveyDataChange(type) {
		if (type === Flux.Constants.SurveyTypes.FEEDBACK) {
			const surveyData = Flux.Survey.getSurveyData(Flux.Constants.SurveyTypes.FEEDBACK);
			if (typeof surveyData.stored !== 'undefined' && surveyData.stored) {
				redirectTimeout = setTimeout(this.timeoutCallback, 2000);

				this.setState({
					sending:      false,
					surveyStored: surveyData.stored,
				});
			} else if (typeof surveyData.timeLimit !== 'undefined' && surveyData.timeLimit) {
				timeLimitTimeout = setTimeout(this.timeLimitCallback, 5000);

				this.setState({
					sending:   false,
					timeLimit: surveyData.timeLimit,
				});
			} else {
				if (typeof surveyData.teasers !== 'undefined') {
					this.setState({
						teasers:            surveyData.teasers,
					});
				}
			}
		}
	}

	storeSurvey() {
		if (!this.state.sending && !this.state.surveyStored && !this.state.modalClosing && !this.state.timeLimit) {
			const feedback     = this.surveyInputFeedback.getPlainText();
			const email        = this.surveyInputEmail.value;
			const isEmailValid = SurveyFeedback.validateEmail(email) || email === '';
			if (feedback.length > 0) {
				if (!isEmailValid) {
					this.setState({
						emailValidationError: true,
					});
				} else {
					Flux.Survey.storeFeedbackAnswer(this.props.isMobile, feedback, email);

					this.setState({
						sending: true,
					});
				}
			} else {
				this.timeoutCallback();
			}

		}
	}

	timeLimitCallback() {
		this.setState({
			timeLimit: false,
		});
	}

	timeoutCallback() {
		this.state.modalClosing = true;
		this.surveyModal.onCloseClick();
	}

	unsetEmailError() {
		this.setState({
			emailValidationError: false,
		});
	}


	render() {
		let survey;
		let timeLimit;
		let teasers;

		if (this.state.surveyStored) {
			survey = (
				<div className="survey__thanks--container">
					<div className="survey__thanks--icon icon -icon-success-rounded-full"></div>
					<div className="survey__thanks--headline">{Translations.get('SurveyLeavePageThanksHeadline')}</div>
				</div>
			);
		} else {
			survey = (
				<div>
					<div className="survey__logo -feedback">
						<img className="survey__logo--img" src={Flux.Browser.isSexole() ? "/assets/img/sexole/sexole_x_visit-x_logo.svg" : "/assets/img/logo2.svg"} />
					</div>
					<div className="survey__container">
						<div className="survey__headline--line1 -small">{Translations.get('SurveyFeedbackHeadline')}</div>
						<div className="survey__headline--line2">{Translations.get('SurveyFeedbackHeadlineBig')}</div>
						<div className="survey__input -margin-xl -no-border">
							<div className={'survey__label -color-green -negative-margintop h-text-align-center h-pos-absolute'}>{Translations.get('SurveyFeedbackTellUs')}</div>
							<TextInput
								onChange={this.onFeedbackMessageChange}
								textInputPlaceholder={Translations.get('SurveyFeedbackTellUs')}
								maxLength={draftJsMaxLength}
								readOnly={false}
								ref={(input) => {
									this.surveyInputFeedback = input;
								}}
								displayRemainingChars={true}
								remainingCharsClassName={"survey__remaining-chars " + (this.state.hasMaxChars ? 'is-max' : '')}
								editorContainerClassName="survey__input -no-margin -no-border"
								rowsAutoGrowMax={5}
							/>
							<div className="survey__headline--label">{Translations.get('SurveyFeedbackEmailLabel')}</div>
							<input className="survey__input--email"
							       ref={(input) => {
								       this.surveyInputEmail = input;
							       }}
							       type="text"
							       defaultValue={Flux.Guest.getEmailAddress()}
							       placeholder={Translations.get('SurveyFeedbackEmailPlaceholder')}
							       onFocus={this.unsetEmailError}
							/>
							{this.state.emailValidationError && <span className="survey__error">
								{Translations.get('SurveyFeedbackEmailValidationError')}
							</span>}
						</div>
						<div className="survey__button-container has-single">
							{this.state.timeLimit ?
								<div className="btn">{Translations.get('PleaseTryAgainLater')}</div> :
								<div onClick={this.storeSurvey}
								     className="btn -btn-color-success"
								>{Translations.get('SurveyFeedbackSendFeedback')}</div>}
						</div>
					</div>
				</div>
			);

			if (this.state.teasers.length > 0 && !this.state.surveyStored && // add teasers
				!this.props.isMobile) {
					teasers = (
						<div className="survey__teasers">
							<div className="survey__teasers--headline">{Translations.get('SurveyLeavePageNewFeatures')}</div>
							<div className="survey__teasers--container">
								{this.state.teasers.map(teaser => (
									<a className="survey__teaser" href={teaser.targetUrl}>
										<span className={'survey__teaser--icon icon ' + teaser.icon} />
										<div className="survey__teaser--headline">{teaser.headline}</div>
										<div className="survey__teaser--subline">{teaser.subline}</div>
									</a>
								))}
							</div>
						</div>
					);
				}

			if (this.state.timeLimit) {
				timeLimit = (
					<div className=""></div>
				);
			}
		}

		return (
			<Modal
				className="-theme-dialog -corners -no-padding"
				onClose={SurveyFeedback.postCloseCleanup}
				ref={(modalRef) => {
					this.surveyModal = modalRef;
				}}
				containerWidth={600}
				dynamicSize={true}
				showSpinner={this.state.sending}
			>
				{timeLimit}
				{survey}
				{teasers}
			</Modal>
		);
	}
}

SurveyFeedback.propTypes = {
	isMobile: PropTypes.bool,
};

SurveyFeedback.defaultProps = {
	isMobile: false,
};

export default SurveyFeedback;
