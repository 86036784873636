import React                 from 'react';
import PropTypes             from 'prop-types';
import Flux                  from '../../flux/Flux';
import SurveyFeedback        from "./SurveyFeedback";

class Survey extends React.Component {

	static disableBodyScroll(disable) {
		if (disable) {
			Flux.VXMobile.disableBodyScroll('survey');
		} else {
			Flux.VXMobile.enableBodyScroll('survey');
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			survey: Flux.Survey.getActiveSurveyType(),
		};

		this.onSurveyActiveChange = this.onSurveyActiveChange.bind(this);
	}

	componentDidMount() {
		Flux.Survey.addSurveyActiveChangeListener(this.onSurveyActiveChange);
	}

	componentWillUnmount() {
		Flux.Survey.removeSurveyActiveChangeListener(this.onSurveyActiveChange);
	}


	onSurveyActiveChange() {
		const activeSurveyType = Flux.Survey.getActiveSurveyType();
		if (activeSurveyType !== this.state.survey) {
			this.setState({
				survey: activeSurveyType,
			});

			if (this.props.isMobile) {
				setTimeout(() => Survey.disableBodyScroll(activeSurveyType !== null), 10);
			}
		}
	}

	render() {
		let content;
		const surveyTypes = Flux.Constants.SurveyTypes;

		if (this.state.survey) {
			switch (this.state.survey) {
				case surveyTypes.FEEDBACK:
					content        = <SurveyFeedback isMobile={this.props.isMobile} />;
					break;
				default:
			}
		}

		if (!content) {
			content = <div></div>;
		}

		return content;
	}
}

Survey.propTypes = {
	isMobile: PropTypes.bool,
};

Survey.defaultProps = {
	isMobile: false,
};

export default Survey;